<template>
  <v-toolbar
    flat
    color="white"
    class="push-notification-toolbar"
  >
    <v-flex xs2>
      <v-select
        v-model="filters.clientId"
        :items="clientFilterItems"
        :label="$t('pushNotifications.filters.selectClient')"
        :menu-props="{'maxWidth': 260}"
        item-value="registryId"
        item-text="name"
        single-line
      />
    </v-flex>
    <v-flex
      v-if="!isFailedTab"
      xs2
      offset-xs1
      text-left
    >
      <date-picker
        v-model="deliveryDate"
        :label="$t('pushNotifications.filters.deliveryDate')"
      />
    </v-flex>
    <v-spacer/>
    <v-btn
      v-if="!isFailedTab"
      color="primary"
      dark
      class="mb-2"
      @click="$emit('addNotification')"
    >
      {{ $t('buttons.add') }}
    </v-btn>
  </v-toolbar>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import DatePicker from '../DatePicker';

export default {
  name: 'PushNotificationsTable',

  components: {
    DatePicker,
  },

  props: {
    isFailedTab: {
      type: Boolean,
      required: true,
      default: false,
    },
    filters: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      deliveryDate: null,
    };
  },

  computed: {
    /**
     * Returns clients which needs to show in filter.
     *
     * @return {Array}
     */
    clients() {
      return this.$store.state.clientsList;
    },

    /**
     * Returns clients list for filter select options.
     *
     * @return {Array}
     */
    clientFilterItems() {
      const items = [];
      items.push({
        registryId: null,
        name: this.$t('pushNotifications.filters.allClients'),
      });
      return [...items, ...this.clients];
    },
  },

  watch: {
    filters: {
      handler() {
        this.$emit('filtersUpdate', this.filters);
      },
      deep: true,
    },
    deliveryDate(value) {
      const filters = _.clone(this.filters);
      if (value) {
        const from = moment(`${value} 00:00:00`);
        const to = moment(`${value} 23:59:59`);
        filters.deliveryDateFrom = from.utc().format();
        filters.deliveryDateTo = to.utc().format();
      } else {
        filters.deliveryDateFrom = null;
        filters.deliveryDateTo = null;
      }
      this.filters = filters;
    },
  },
};
</script>

<style lang="scss">
.push-notification-toolbar div.v-toolbar__content {
  height: 74px!important;
}
</style>
