<template>
  <div>
    <v-card-text>
      <span class="d-inline">{{ $t('incidents.jsonUploaderDescription') }}:</span>
      <uploader
        ref="uploader"
        :allowed-mime-types="[]"
        :no-title-update="true"
        :title="$t('incidents.uploadJson')"
        class="d-inline-block"
        fixed-width="200px"
        @file-update="fileUpdated"
      />
    </v-card-text>
    <upload-json-popup
      ref="uploadJsonPopup"
      :clients="clients"
      @close="clearFile"
    />
  </div>
</template>

<script>
import Uploader from '@/components/Uploader';
import UploadJsonPopup from './UploadJsonPopup';

export default {
  name: 'JsonUploader',

  components: {
    Uploader,
    UploadJsonPopup,
  },

  props: {
    clients: {
      type: Array,
      required: true,
    },
  },

  methods: {
    /**
     * Handler when file is selected.
     *
     * @param {Object} file - Selected file object
     */
    fileUpdated(file) {
      if (file) {
        this.$refs.uploadJsonPopup.show(file);
      }
    },

    /**
     * Clear file in uploader.
     */
    clearFile() {
      this.$refs.uploader.clear();
    },
  },
};
</script>
