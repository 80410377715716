<template>
  <v-dialog
    v-model="showPopup"
    max-width="600"
    persistent
  >
    <v-card>
      <v-card-title class="headline">{{ $t('incidents.createIncidentByJson') }}</v-card-title>
      <v-card-text>
        <v-alert
          v-model="errorAlert"
          type="error"
          outlined
          dismissible
          transition="scale-transition"
        >
          <div v-html="errorMessage" />
        </v-alert>
        <v-form>
          <v-layout wrap>
            <v-flex xs7>
              <v-text-field
                v-model="fileOriginalName"
                :error-messages="errors.collect('tmpFilename')"
                :label="$t('general.file')"
                name="tmpFilename"
                readonly
              />
            </v-flex>
            <v-flex xs5>
              <uploader
                ref="uploader"
                v-model="file"
                :title="$t('incidents.uploadJson')"
                :no-title-update="true"
                :allowed-mime-types="[]"
                fixed-width="100%"
                @file-update="fileUpdated"
              />
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="clientId"
                v-validate="{ required: true }"
                :items="clients"
                :error-messages="errors.collect('client')"
                :label="$t('general.selectClient')"
                item-value="registryId"
                item-text="name"
                name="client"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="sequenceNumber"
                v-validate="{ required: true }"
                :error-messages="errors.collect('sequenceNumber')"
                :label="$t('general.sequenceNumber')"
                name="sequenceNumber"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="blue darken-1"
          text
          @click.native="close"
        >{{ $t('buttons.cancel') }}</v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          text
          @click.native="upload"
        >{{ $t('buttons.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Uploader from '@/components/Uploader';
import errorAlertMixins from '@/mixins/errorAlertMixins';
import { CREATE } from '@/store/Incidents/actions';

const { mapActions } = createNamespacedHelpers('incidents');

export default {
  name: 'UploadJsonPopup',
  components: {
    Uploader,
  },

  mixins: [errorAlertMixins],

  props: {
    clients: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      file: null,
      showPopup: false,
      sequenceNumber: null,
      clientId: null,
      loading: false,
      tmpFilename: null,
      // clients: null,
      dictionary: {
        attributes: {
          sequenceNumber: this.$t('general.sequenceNumber'),
          client: this.$t('general.client'),
          tmpFilename: this.$t('general.file'),
        },
      },
    };
  },

  computed: {
    /**
     * Returns clients which needs to show in filter.
     *
     * @return {String}
     */
    fileOriginalName() {
      return this.file ? this.file.name : null;
    },
  },

  mounted() {
    this.$validator.localize('en', this.dictionary);
  },
  methods: {
    ...mapActions({
      create: CREATE,
    }),
    /**
     * Show upload JSON popup
     */
    show(file) {
      this.file = file;
      this.showPopup = true;
      this.sequenceNumber = null;
      this.clientId = null;
      this.tmpFilename = null;
      this.$validator.reset();
      this.errors.clear();
    },

    /**
     * Upload JSON and create incident.
     *
     * @return {null}
     */
    async upload() {
      await this.$validator.reset();
      this.errors.clear();
      if (!await this.$validator.validateAll()) {
        return;
      }
      try {
        this.loading = true;
        if (!this.tmpFilename) {
          const presignedData = await this.$refs.uploader.upload();
          this.tmpFilename = presignedData.tmpFilename;
        }
        await this.create({
          clientId: this.clientId,
          sequenceNumber: this.sequenceNumber,
          tmpFilename: this.tmpFilename,
        });
        this.close();
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Close Popup.
     */
    close() {
      this.showPopup = false;
      this.$emit('close');
    },

    /**
     * Updated file in uploader button.
     *
     * @param {File} file - Object of file
     */
    fileUpdated(file) {
      if (file) {
        this.tmpFilename = null;
      }
    },
  },
};
</script>
